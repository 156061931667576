import React from "react"
import { graphql } from "gatsby"

import Button from 'react-bootstrap/Button';


export default function Forward({ data }) {
	const stream = data.markdownRemark.frontmatter
	return (
		<>
			<div style={{height: "100vh", width:"100vw", display: "absolute", backgroundColor: "#eee", paddingTop: "10vh" }}> 
				<a href={stream.embedurl}>
					<center>
						<Button className="btn-dark">Um den Stream zu starten, hier klicken und zu Drittanbieter weiterleiten<br/>
							<small>Öffnet {stream.embedurl} in diesem Frame.</small>
						</Button>
					</center>
				</a>
			</div>
		</>
	);
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				event
		date
		embedurl
		note
			}
		}
	}
`
